// Custom
.table-link {
    color: #ff3465;
    font-weight: 600;
}
.table-link-dark {
    color: #000;
    font-weight: 600;
}
.table-link-dark-no-weight {
    color: #000;
}
.table-main-column {
    font-weight: 600;
}
.color-ethereum {
    background-color: #ddebf9;
}
.color-bsc {
    background-color: #fef7df;
}
.color-polygon {
    background-color: #eee8f4;
}
.color-celo {
    background-color: #e8f2dd;
}
.color-avalanche {
    background-color: #f2dddd;
}
